.contact-form {
    /* margin-left: 300px !important; */
}

.email {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.email svg{
    fill: var(--primary-color-light);
    position: relative;
    top: 17px;
    margin: 0 10px;
}


@media screen and (max-width: 600px){
    #contactsubmit{
        position: relative;
        left: 15px;
    }
}

@media screen and (min-width: 600px){
    .contact-form {
        margin-left: 0 !important;
    }
}

@media screen and (min-width: 900px){
    .contact-form {
        margin-left: 90px !important;
    }
}

@media screen and (min-width: 1200px){
    .contact-form {
        margin-left: 130px !important;
    }    
}

@media screen and (min-width: 1250px){
    .contact-form {
        margin-left: 300px !important;
    }    
}
