.footer-hr{
    margin: 25px 0;
    background: var(--primary-color);
    opacity: .2;
    margin: 0 auto;
    margin-top: 10rem;
    width: 80%;
}

.container.footer{
    padding: 10px 20px;
    margin-top: 40px;
    margin-bottom: 70px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.container.footer .footer-items:last-child{
    
}
.container.footer .footer-items:last-child > span{
    color: var(--primary-color-light);
    font-size: 10px;
    
}
.container.footer .footer-items:last-child > svg{
    height: 15px;
    width: 30px;
    margin: -4px 0;
    fill: var(--primary-color-light);
    margin-left: -10px;
}

.footer-items{
    margin: 0 auto;
}
.footer-items ul{
    list-style: none;
    display: flex;
    margin: 10px;
    padding-inline-start: 0;
}
.footer-items > a{
    font-size: 15px;   
}

.footer-item{
    padding: 0 5px;
}
.footer-item a > svg{
    height: 20px;
    width: 20px;
}
.footer-item a > svg:hover{
    fill: var(--primary-color-light);
    transition: var(--common-transition);
    --webkit-transition: var(--common-transition);
}