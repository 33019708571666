.container.skills{

}
.wrapper.skills{
    margin: 50px 40px;
}
.wrapper.skills > div{
    margin: 15px 0;
}

.skills-container{
    display: flex;
    flex-direction: column;
}
.skills-container > div {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.skills-container > h4{
    text-align: left;
}

.tag.skill{
    width: fit-content;
    margin: 5px;
}