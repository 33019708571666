.navWrapper {
    background: var(--nav-bg);
    position: fixed;
    height: 100vh;
    bottom: 0;
    width: 65px;
    z-index: 1000;
    transition: all .5s ease;
}
.navWrapper.expand {
    width: 220px;
    transition: var(--common-transition);
    --webkit-transition: var(--common-transition);
}

nav{
    display: flex;
    flex-direction: column;
}

.logoWrapper{
    max-height: 20vh;
    min-height: 20vh;
}

.logo{
    display: flex;
}
.logo > img {
    opacity: 0;
    max-width: 40px;
    margin: 15px -20px;
    object-fit: contain;
    transition: var(--common-transition);
    --webkit-transition: var(--common-transition);
}
.logo > svg {
    transform: scale(1.1);
    cursor: pointer;
    margin: 15px;
}

.navWrapper.expand .logo > img {
    opacity: 1;
    margin: 15px 10px;
    margin-right: 110px;
    transition: var(--common-transition);
    --webkit-transition: var(--common-transition);
}
.navWrapper.expand .logo > svg > path{
    d: path('M4 6h16v2H4zm4 5h12v2H8zm5 5h7v2h-7z');
    transition: var(--common-transition);
    --webkit-transition: var(--common-transition);
}

.name {
    margin: 10px 35px;
}
.name h3, .name h5{
    opacity: 0;
    margin-left: -200px;
    transition: var(--common-transition);
    --webkit-transition: var(--common-transition);
}
.navWrapper.expand .name h3, .navWrapper.expand .name h5{
    opacity: 1;
    margin-bottom: -3px;
    margin-left: 0px;
    transition: var(--common-transition);
    --webkit-transition: var(--common-transition);
}
.navWrapper.expand .name h5{
    margin: 6px 10px;
    color: var(--primary-color-light);
}

.navItemsWrapper{
    min-height: 80vh;
    max-height: 80vh;
}

.navItems{
    
}
ul.navItems{
    list-style: none;
}

.navItem{

}
li.navItem{
    height: 45px;
    width: 45px;
    margin: 50px 0 0 -23px;
    transition: var(--common-transition);
    --webkit-transition: var(--common-transition);
}
li.navItem a{
    text-decoration: none;
    transition: none;
}
li.navItem a > span {
    font-size: 15px;
    position: relative;
    top: -35px;
    left: -30px;
    opacity: 0;
    transition: var(--common-transition);
    --webkit-transition: var(--common-transition);
}
li.navItem a:hover > svg{
    fill: var(--primary-color-light);
    transition: var(--common-transition);
    --webkit-transition: var(--common-transition);
}

.navItem.selected{
    
}
.navItem.selected a{
    
}
.navItem.selected a > span {
    color: var(--primary-color-light);
}
.navItem.selected a > svg{
    fill: var(--primary-color-light);
}
.navItem.selected a:hover > svg{
    fill: var(--primary-color-light);
}

.navWrapper.expand .navItemsWrapper .navItems li.navItem{
    margin-left: -10px;
    transition: var(--common-transition);
    --webkit-transition: var(--common-transition);
}
.navWrapper.expand .navItemsWrapper .navItems li.navItem a > span{
    opacity: 1;
    left: 70px;
    transition: var(--common-transition);
    --webkit-transition: var(--common-transition);
}

@media screen and (max-width: 600px){
    .navWrapper{
        height: 100vw;
        bottom: -1px;
    }
}

@media screen and (max-width: 599px){
    .navWrapper, .navWrapper.expand{
        height: 60px;
        width: 100vw;
        bottom: -1px;
    }
    nav{
        flex-direction: row;
    }
    .logoWrapper{
        display: none;
    }
    .navItemsWrapper {
        min-height: 60px;
        max-height: 60px;
        max-width: 100%;
        min-width: 100%;
    }
    ul.navItems {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content:space-around;
        padding-inline-start: 0;
        margin: 11px 0;
    }
    li.navItem {
        margin: 0;
        height: 40px;
        width: 30px;
    }
    li.navItem svg {
        height: 40px;
        width: 30px;
    }
    li.navItem a > span {
        display: none;    
    }

    /* override expand */
    .container.expand {
        margin: 0 auto;
    }
}