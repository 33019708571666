header{

}

.container.home{

}


.home-item{
    margin: 10px auto;
}

.home-item.dp{
    width: 400px;
}
.home-item.dp img{
    width: 100%;
    margin: 0 auto;
    border-radius: 50%;
    outline: 1px solid var(--primary-color);
    filter: hue-rotate(-7deg) contrast(.85) brightness(0.9);
}

.home-item.intro{
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 10px;
}
.home-item.intro > h1{
    margin-top: -15px;
    margin-bottom: -2px;
    color: var(--primary-color-light);
}

.home-action{
    display: flex;
    /* padding: 15px 10px 0px 10px; */
    margin: 10px auto;
}

.home-action .button{
    margin: 0px 25px;
}

@media screen and (max-width: 1024px){
    .home-item.dp{
        width: 300px;
    }
}

@media screen and (max-width: 600px){
    .home-item.dp{
        width: 250px;
    }
}