@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

:root{
    --bg: #1a2b34;
    --bg-body: #060606f0;
    /* --primary-color-light: #c4e1f2;
    --primary-color: #4eadee;
    */
    --nav-bg:  #33005b;
    --rebecca: rebeccapurple;
    --primary-color: rebeccapurple;
    --primary-color-2: #7500D1;
    --primary-color-medium: #A32EFF;
    --primary-color-light: #CB8AFF;
    --card-bg: #3d3d3d52;

    --white-variant: #ede9f1;
    --black-variant: #15172b; 
    --light-violet: #2f366e;
    --primary-color2: #4e7bac;
    --dark-violet: #242757;
    --pale-red: #ab5362;
    --brown: #6a4b42;
    --mineral-green: #3f5554;

    --container-width: 75%;
    --container-width-tablet: 86%;
    --container-width-mobile: 90%;

    --common-transition: all 400ms ease;
    --border-radius: 15px;
}

body{
    background: var(--bg-body);
    margin: 0;
    padding: 0;
    list-style: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
    overflow-x: hidden;
}

html{
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display: none;
}

.container{
    width: var(--container-width);
    margin: 0 auto;
    margin-left: 180px;
    transition: var(--common-transition);
    --webkit-transition: var(--common-transition);
}

.container.expand {
    margin-left: 275px;
    transition: var(--common-transition);
    --webkit-transition: var(--common-transition);
}

.container.home, .container.about, .container.portfolio, .container.skills, .container.experience{
    height: fit-content;
}

.wrapper, .wrapper > div{
    margin: 75px 0;
}

h1, h2, h3, h4, h5{
    font-weight: 500;
    text-align: center;
}

h1{
    font-size: 2.5rem;
}

h2 {
    font-size: 1.7rem;
}

h5 {
    font-size: .75em;
}

section{
    margin-top: 3.5rem;
}

section > h2, section > h5{
    text-align: center;
    color: var(--primary-color-light);
}

section > h2{
    color: var(--primary-color);
    margin-bottom: 3rem;
}

.text-light{
    color: var(--primary-color-light);
}

p{
    font-size: 0.9rem;
    text-align: justify;
}

.highlight{
    color: var(--primary-color-light);
}

.card{
    text-align: center;
    padding: 25px;
    background: var(--card-bg);
    border-radius: var(--border-radius);
}

.tag-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.tag{
    border: 1px solid var(--primary-color);
    padding: 0.3rem 0.8rem;
    border-radius: 30px;
    font-size: .7rem;
    background: var(--white-variant);
    color: var(--black-variant);
    user-select: none;
}

.tag.large{
    padding: 0.2rem 0.8rem;
    font-size: .9rem;
}

a{
    color: white;
    text-decoration: none;
    transition: var(--common-transition);
    --webkit-transition: var(--common-transition);
}

a:hover{
    color: var(--primary-color-light);
}

.button{
    width: max-content;
    color: var(--primary-color-light);
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: .4rem;
    cursor: pointer;
    border: 1px solid var(--primary-color);
    transition: var(--common-transition);
    --webkit-transition: var(--common-transition);
}

.button:hover{
    background-color: white;
    color: var(--bg-body);
    border-color: transparent;
}

.button.primary{
    background-color: var(--primary-color);
    color: var(--bg-body);
}

img{
    display: block;
    width: 100%;
    object-fit: cover;
}

svg{
    height: 30px;
    width: 30px;
    fill: white;
}

.next-route {
    position: relative;
    right: calc(-100% + 50px);
    bottom: -50px;
    font-size: .7rem;
}

div#root {
    overflow-x: hidden;
}

.next-route::before, .prev-route::after{
    content: '>>';
    margin: 0px 5px;
}
.prev-route, .prev-route.contact {
    position: relative;
    left: 0;
    bottom: -75px;
    font-size: .7rem;
}
.prev-route::after{
    content: '<<';
}
.prev-route.contact{
    bottom: -50px;
}

hr{
    width: 100%;
    margin: 25px 0;
    height: 0.1px;
    border-width: 0;
    background: white;
    opacity: .1;
}

section h1 span:last-child{
    color: var(--primary-color);
}

.sub-title{
    text-align: left;
    text-decoration: underline;
    text-decoration-color: var(--primary-color-2);
    text-decoration-thickness: 3px;
}

span.sub-title{

}

.timeline {
    display: flex;
    flex-direction: column;
}

/* Animations */
@keyframes color-train {
    0%{
        color: var(--white-variant);
    }
    50%{
        color: var(--primary-color-2);
    }
    100%{
        color: var(--primary-color2);
    }
}


@media screen and (max-width: 1024px){
    .container{
        width: var(--container-width-tablet);
        margin-left: 85px;
    }
    .container.expand {
        margin-left: 100px;
    }
    .section{
        margin-top: 6rem;
    }
    .next-route, .prev-route{
        display: none;    
    }
}

@media screen and (max-width: 600px){
    .container{
        width: var(--container-width-mobile);
        margin: auto;
    }
    .section > h2{
        margin-bottom: 2rem;
    }
    .next-route, .prev-route{
        display: none;    
    }
}